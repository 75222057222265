<template>
	<div class="team-preview">
		<div class="team-wrapper border">
			<v-tabs v-model="tab" color="blue darken-4">
				<v-tabs-slider></v-tabs-slider>

				<v-tab href="#issue"> Issued </v-tab>

				<v-tab href="#receive"> Received </v-tab>

				<v-tab href="#retire"> Retired </v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item value="issue">
					<div class="team-fields pb-2" v-if="tab == 'issue'">
						<div class="team-grp">
							<DashboardAsset
								:d-transaction-type-list="transactionTypeList"
								:d-status-list="statusList"
								:d-incharge-list="inchargeList"
								:d-customer-list="customerList"
								:d-project-list="projectList"
								:d-supplier-list="supplierList"
								:d-category-list="categoryList"
								d-type="issue"
								d-page-title="Issue"
								d-endpoint="reservation"
								:d-default-filter="{ status: 'checked-out', dashboard: 1 }"
							></DashboardAsset>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="receive">
					<div class="team-fields pb-2" v-if="tab == 'receive'">
						<div class="team-grp">
							<DashboardAsset
								:d-transaction-type-list="transactionTypeList"
								:d-status-list="statusList"
								:d-incharge-list="inchargeList"
								:d-customer-list="customerList"
								:d-project-list="projectList"
								:d-supplier-list="supplierList"
								:d-category-list="categoryList"
								d-type="receive"
								d-page-title="Receive"
								d-endpoint="checkin"
								:d-default-filter="{ dashboard: 1 }"
							></DashboardAsset>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="retire">
					<div class="team-fields pb-2" v-if="tab == 'retire'">
						<div class="team-grp">
							<DashboardAsset
								:d-transaction-type-list="transactionTypeList"
								:d-status-list="statusList"
								:d-incharge-list="inchargeList"
								:d-customer-list="customerList"
								:d-project-list="projectList"
								:d-supplier-list="supplierList"
								:d-category-list="categoryList"
								d-type="retired"
								d-page-title="Retire"
								d-endpoint="retire"
								:d-default-filter="{ dashboard: 1 }"
							></DashboardAsset>
						</div>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<style>
.custom-width {
	width: 50%;
	white-space: normal;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import DashboardAsset from "@/view/pages/components/DashboardAsset";
import ObjectPath from "object-path";

export default {
	name: "team-member",
	data() {
		return {
			tab: "issue",
			assetdata: [],
			transactionTypeList: [],
			statusList: [],
			inchargeList: [],
			customerList: [],
			projectList: [],
			supplierList: [],
			categoryList: [],
			transactionType: [],
			status: [],
			incharge: [],
			customer: [],
			project: [],
			supplier: [],
			category: [],
		};
	},
	components: {
		DashboardAsset,
	},
	methods: {
		getFilters() {
			ApiService.setHeader();
			ApiService.get("dashboard-filters").then((output) => {
				this.transactionTypeList = ObjectPath.get(output, "data.transaction_type", []);
				this.statusList = ObjectPath.get(output, "data.status", []);
				this.inchargeList = ObjectPath.get(output, "data.incharge", []);
				this.customerList = ObjectPath.get(output, "data.customer", []);
				this.projectList = ObjectPath.get(output, "data.project", []);
				this.supplierList = ObjectPath.get(output, "data.supplier", []);
				this.categoryList = ObjectPath.get(output, "data.category", []);
			});
		},
		getAssets() {
			/*const issued_url = "http://127.0.0.1:8000/api/v1/reservation?status=checked-out&t=1663840055968&tz=1663842425696";
			const issued_url = "http://127.0.0.1:8000/api/v1/checkin?status=all&t=1663842369135&tz=1663842463828";
			const issued_url = "http://127.0.0.1:8000/api/v1/retire?status=all&t=1663842369135&tz=1663842479081";
			const issued_url = "http://127.0.0.1:8000/api/v1/reservation?status=reserved&t=1663842369135&tz=1663842494853";*/
			ApiService.setHeader();
			ApiService.query("dashboard-asset", { date: this.status }).then(({ data }) => {
				this.assetdata = data.to_asset;
			});
		},
	},
	mounted() {
		this.getFilters();
	},
};
</script>
<style scoped>
.v-progress-circular {
	margin: 1rem;
}
</style>

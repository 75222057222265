<template>
	<div class="project-revenue-dashboard border">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Transactions</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_overview"
				:items="projectFilterList"
				hide-details
				dense
				left
				solo
				item-text="text"
				item-value="value"
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item.text }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="bar"
					height="358"
					:options="chartOptions"
					:series="series"
					width="100%"
				></apexchart>
			</div>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_overview: "this_month",
			projectFilterList: [
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "This Year", value: "this_year" },
			],
			series: [
				{
					name: "Assets",
					data: [23, 11, 42, 27],
				},
				{
					name: "Asset Stocks",
					data: [44, 55, 41, 17],
				},
				{
					name: "Perishables",
					data: [30, 25, 36, 30],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				labels: [
					"01/01/2003",
					"02/01/2003",
					"03/01/2003",
					"04/01/2003",
					"05/01/2003",
					"06/01/2003",
					"07/01/2003",
					"08/01/2003",
					"09/01/2003",
					"10/01/2003",
					"11/01/2003",
				],
				markers: {
					size: 0,
				},
				xaxis: {
					categories: ["Checkouts", "Checkins", "Reservations", "Retirement"],
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " points";
							}
							return y;
						},
					},
				},
			},
		};
	},
	methods: {
		getchartData() {
			ApiService.setHeader();
			ApiService.query("dashboard-chart", { date: this.project_overview }).then(({ data }) => {
				// this.series = data;
				console.log(data);
			});
		},
	},
	mounted() {
		this.getchartData();
	},
};
</script>

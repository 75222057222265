<template>
	<div class="border h-100">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Overall Stats</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="overall_status"
				:items="overall_list"
				v-on:change="getOverallList()"
				hide-details
				dense
				left
				solo
				item-text="text"
				item-value="value"
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item.text }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Issued </span>
				</p>
				<div class="">
					<p class="fw-600 green--text mb-0" style="font-size: 30px">{{ checkout.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="checkout.percent" color="green">
					{{ checkout.percent }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Received </span>
				</p>
				<div class="">
					<p class="fw-600 red--text mb-0" style="font-size: 30px">{{ checkin.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="checkin.percent" color="red">
					{{ checkin.percent }}%
				</v-progress-circular>
			</div>
		</div>
		<div
			v-if="false"
			class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center"
		>
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Receive Overdue </span>
				</p>
				<div class="">
					<p class="fw-600 indigo--text mb-0" style="font-size: 30px">{{ pending.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="pending.percent" color="red">
					{{ pending.percent }}%
				</v-progress-circular>
			</div>
		</div>
		<div
			v-if="false"
			class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center"
		>
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Maintenance </span>
				</p>
				<div class="">
					<p class="fw-600 indigo--text mb-0" style="font-size: 30px">{{ service.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="service.percent" color="indigo">
					{{ service.percent }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Reserved </span>
				</p>
				<div class="">
					<p class="fw-600 orange--text mb-0" style="font-size: 30px">{{ reserved.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="reserved.percent" color="orange">
					{{ reserved.percent }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Retired </span>
				</p>
				<div class="">
					<p class="fw-600 cyan--text mb-0" style="font-size: 30px">{{ retired.value }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="retired.percent" color="cyan">
					{{ retired.percent }}%
				</v-progress-circular>
			</div>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";

export default {
	name: "OverAllStats",
	data() {
		return {
			overall_status: "asset",
			overall_list: [
				{ text: "Asset", value: "asset" },
				{ text: "Asset Stock", value: "asset-stock" },
				{ text: "Perishable", value: "inventory" },
			],
			checkout: {
				value: 0,
				percent: 0,
			},
			checkin: {
				value: 0,
				percent: 0,
			},
			pending: {
				value: 0,
				percent: 0,
			},
			service: {
				value: 0,
				percent: 0,
			},
			reserved: {
				value: 0,
				percent: 0,
			},
			retired: {
				value: 0,
				percent: 0,
			},
		};
	},
	methods: {
		getOverallList() {
			ApiService.setHeader();
			ApiService.get(`dashboard-overall-state?type=${this.overall_status}`).then((output) => {
				this.checkout = ObjectPath.get(output, "data.checkout", {});
				this.checkin = ObjectPath.get(output, "data.checkin", {});
				this.pending = ObjectPath.get(output, "data.pending", {});
				this.service = ObjectPath.get(output, "data.service", {});
				this.reserved = ObjectPath.get(output, "data.reserved", {});
				this.retired = ObjectPath.get(output, "data.retired", {});
			});
		},
	},
	mounted() {
		this.getOverallList();
	},
};
</script>

<template>
	<div>
		<table width="100%" style="table-layout: fixed">
			<tr>
				<td class="py-3 px-2">
					<span class="my-auto font-level-3-bold fw-600">{{ dPageTitle }}</span>
				</td>
				<td class="py-3 px-2" v-if="dTransactionTypeList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('transactionType')"
						v-model="transactionType"
						:items="dTransactionTypeList"
						hide-details
						custom-class="pt-0"
						placeholder="Type"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dStatusList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('status')"
						v-model="status"
						:items="dStatusList"
						hide-details
						custom-class="pt-0"
						placeholder="Status"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dInchargeList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('incharge')"
						v-model="incharge"
						:items="dInchargeList"
						hide-details
						custom-class="pt-0"
						placeholder="Incharge Officer"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dCustomerList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('customer')"
						v-model="customer"
						:items="dCustomerList"
						hide-details
						custom-class="pt-0"
						placeholder="Customer"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dProjectList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('project')"
						v-model="project"
						:items="dProjectList"
						hide-details
						custom-class="pt-0"
						placeholder="Project"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dSupplierList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('supplier')"
						v-model="supplier"
						:items="dSupplierList"
						hide-details
						custom-class="pt-0"
						placeholder="Supplier"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
				<td class="py-3 px-2" v-if="dCategoryList.length">
					<select-input
						clearable
						v-on:click:clear="clearFilter('category')"
						v-model="category"
						:items="dCategoryList"
						hide-details
						custom-class="pt-0"
						placeholder="Category"
						multiple
						v-on:change="filterList()"
					></select-input>
				</td>
			</tr>
		</table>
		<div class="mx-2">
			<Table
				:page-loading="pageLoading"
				:type="dType"
				v-on:reload:content="filterRows"
				delete-note="All transactions of this group will also be deleted."
			></Table>
		</div>
	</div>
</template>

<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import SelectInput from "@/view/components/SelectInput";
import { isString, toSafeInteger } from "lodash";
import ObjectPath from "object-path";

export default {
	name: "dashboard-asset",
	title: "Dashboard Asset",
	mixins: [ListingMixin],
	components: {
		SelectInput,
	},
	props: {
		dType: {
			type: String,
			default: null,
		},
		dPageTitle: {
			type: String,
			default: null,
		},
		dEndpoint: {
			type: String,
			default: null,
		},
		dDefaultFilter: {
			type: Object,
			default() {
				return {};
			},
		},
		dTransactionTypeList: {
			type: Array,
			default() {
				return [];
			},
		},
		dStatusList: {
			type: Array,
			default() {
				return [];
			},
		},
		dInchargeList: {
			type: Array,
			default() {
				return [];
			},
		},
		dCustomerList: {
			type: Array,
			default() {
				return [];
			},
		},
		dProjectList: {
			type: Array,
			default() {
				return [];
			},
		},
		dSupplierList: {
			type: Array,
			default() {
				return [];
			},
		},
		dCategoryList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			pageTitle: null,
			endpoint: null,
			defaultFilter: {},
			transactionType: [],
			status: [],
			incharge: [],
			customer: [],
			project: [],
			supplier: [],
			category: [],
			timeout: null,
			timeoutLimit: 500,
		};
	},
	methods: {
		filterList() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.$router
					.replace({
						name: _this.$route.name,
						query: {
							..._this.$route.query,
							"d-transaction-type": _this.transactionType,
							"d-status": _this.status,
							"d-incharge": _this.incharge,
							"d-customer": _this.customer,
							"d-project": _this.project,
							"d-supplier": _this.supplier,
							"d-category": _this.category,
						},
					})
					.then(() => {
						_this.getListing();
					})
					.catch(() => {});
			}, _this.timeoutLimit);
		},
		clearFilter(type) {
			this[type] = null;
			this.$nextTick(() => {
				this.filterList();
			});
		},
	},
	mounted() {
		const d_transaction_type = ObjectPath.get(this.$route, "query.d-transaction-type", []);
		const d_status = ObjectPath.get(this.$route, "query.d-status", []);
		const d_incharge = ObjectPath.get(this.$route, "query.d-incharge", []);
		const d_customer = ObjectPath.get(this.$route, "query.d-customer", []);
		const d_project = ObjectPath.get(this.$route, "query.d-project", []);
		const d_supplier = ObjectPath.get(this.$route, "query.d-supplier", []);
		const d_category = ObjectPath.get(this.$route, "query.d-category", []);

		if (isString(d_transaction_type)) {
			this.transactionType = [d_transaction_type];
		} else {
			this.transactionType = d_transaction_type;
		}

		if (isString(d_status)) {
			this.status = [d_status];
		} else {
			this.status = d_status;
		}

		if (isString(d_incharge)) {
			this.incharge = [toSafeInteger(d_incharge)];
		} else {
			this.incharge = d_incharge;
		}

		if (isString(d_customer)) {
			this.customer = [toSafeInteger(d_customer)];
		} else {
			this.customer = d_customer;
		}

		if (isString(d_project)) {
			this.project = [toSafeInteger(d_project)];
		} else {
			this.project = d_project;
		}

		if (isString(d_supplier)) {
			this.supplier = [toSafeInteger(d_supplier)];
		} else {
			this.supplier = d_supplier;
		}

		if (isString(d_category)) {
			this.category = [toSafeInteger(d_category)];
		} else {
			this.category = d_category;
		}
	},
	beforeMount() {
		this.pageTitle = this.dPageTitle;
		this.endpoint = this.dEndpoint;
		this.defaultFilter = this.dDefaultFilter;
	},
};
</script>

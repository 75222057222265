<template>
	<div class="project-revenue-dashboard border">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Asset by Warranty Reminder</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_overview"
				:items="projectFilterList"
				v-on:change="getchartData()"
				hide-details
				dense
				left
				solo
				item-text="text"
				item-value="value"
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item.text }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<div class="v-sheet theme--light">
					<div class="v-data-table bt-table theme--light">
						<div class="v-data-table__wrapper">
							<table width="100%">
								<thead>
									<tr>
										<th>Image</th>
										<th>Asset #</th>
										<th>Asset Name</th>
										<th>Warranty</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in list" :key="index">
										<td>
											<ImageTemplate circle style="max-width: 50px" :src="row.image"></ImageTemplate>
										</td>
										<td>
											<Chip :text="row.barcode" color="cyan"></Chip>
										</td>
										<td>
											<ShowValue :object="row" object-key="name" label="asset name"></ShowValue>
										</td>
										<td>
											<p class="m-0 custom-nowrap-ellipsis" v-if="row.warranty_start_date_formatted">
												<b>From: </b> {{ row.warranty_start_date_formatted }}
											</p>
											<p class="m-0 custom-nowrap-ellipsis" v-if="row.warranty_end_date_formatted">
												<b>To: </b> {{ row.warranty_end_date_formatted }}
											</p>
											<p class="m-0 custom-nowrap-ellipsis" v-if="row.warranty_reminder_date_formatted">
												<b>Reminder: </b> {{ row.warranty_reminder_date_formatted }}
											</p>
										</td>
										<td>
											<v-chip
												:color="getStatus(row.status_value) ? getStatus(row.status_value).color : ''"
												:text-color="getStatus(row.status_value) ? getStatus(row.status_value).textcolor : ''"
												label
											>
												{{ getStatus(row.status_value) ? getStatus(row.status_value).text : "" }}
											</v-chip>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";
import ObjectPath from "object-path";
import { find } from "lodash";

export default {
	name: "AssetWarranty",
	data() {
		return {
			project_overview: "this_month",
			projectFilterList: [
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "This Year", value: "this_year" },
			],
			list: [],
		};
	},
	components: {
		ShowValue,
		Chip,
		ImageTemplate,
	},
	methods: {
		getchartData() {
			ApiService.setHeader();
			ApiService.query("dashboard-warranty", { type: this.project_overview }).then((output) => {
				this.list = ObjectPath.get(output, "data.list");
				this.status = ObjectPath.get(output, "data.status");
			});
		},
		getStatus(value) {
			return find(this.status, { value });
		},
	},
	mounted() {
		this.getchartData();
	},
};
</script>

<template>
	<div class="team-preview">
		<div class="team-wrapper border">
			<div class="team-fields pb-2">
				<div class="team-grp">
					<v-layout class="px-4 py-2 border-bottom">
						<div class="my-auto font-level-3-bold fw-600">Check In</div>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<v-select
								class="cm-menu-select elevation-0 red--text"
								v-model="status"
								:items="StatusList"
								hide-details
								dense
								solo
								v-on:change="getAssets"
								item-text="text"
								item-value="value"
							>
								<template v-slot:selection="{ item }">
									<span class="blue--text font-level-3-bold">{{ item.text }}</span>
								</template>
							</v-select>
						</div>
					</v-layout>
					<div class="v-data-table bt-table theme--light">
						<div class="v-data-table__wrapper_custom">
							<table id="table" width="100%">
								<thead>
									<tr>
										<th>Asset Name</th>
										<th>Barcode</th>
										<th>Purchased On</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(row, index) in assetdata">
										<tr :key="index">
											<td style="white-space: normal" width="35%">
												<div class="d-flex align-items-center">
													<v-avatar rounded size="40">
														<ImageTemplate circle :src="row.image"></ImageTemplate
													></v-avatar>
													<div class="user_profile">
														<h4 style="font-size: 1rem">{{ row.name }}</h4>
													</div>
												</div>
											</td>
											<td>
												<Chip :text="row.barcode" color="cyan"></Chip>
											</td>
											<td>
												<p class="fw-500 mb-0">{{ row.purchased_on }}</p>
											</td>

											<td>
												<Chip :text="row.activated" color="green" class="fw-500 mb-0"></Chip>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
.custom-width {
	width: 50%;
	white-space: normal;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
export default {
	name: "team-member",
	data() {
		return {
			assetdata: {},
			status: "this_month",
			StatusList: [
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "This Year", value: "this_year" },
			],
		};
	},
	components: {
		ImageTemplate,
		Chip,
	},

	methods: {
		getAssets() {
			ApiService.setHeader();
			ApiService.query("dashboard-asset", { date: this.status }).then(({ data }) => {
				this.assetdata = data.to_asset;
			});
		},
	},
	mounted() {
		this.getAssets();
	},
};
</script>
<style scoped>
.v-progress-circular {
	margin: 1rem;
}
</style>
